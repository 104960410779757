import React from "react";
import Layout from "../components/layout";

import AccountActivate from "../components/internal-activate";
import ChwebContext from "../components/context";
import { LocationInfo } from "../libs/location";

type ActiveteScreenProps = {
    location: LocationInfo
};

export default function ActivateScreen({location}: ActiveteScreenProps) {
    return <Layout locale={"fr"} location={location}>
        <ChwebContext />
        <AccountActivate />
    </Layout>;
}