import React, { ReactElement, useContext, useState} from "react";
import queryString from "query-string";

import { InterractiveSection, handleAccountActivate, SubmitButton, SecretForm } from "@chweb/commonui";
import { LocationContext } from "./location";

const sectionTitle = "Activation du Compte";

export default function AccountSettings(): ReactElement {
    const location = useContext(LocationContext);
    if (!location)
        return <></>;

    const urlParams = queryString.parse(location.search);
    if (!urlParams.invite || !urlParams.source) {
        return <></>;
    }

    const [ isSuccess, setIsSuccess] = useState<boolean>(false);
    const [ updateError, setUpdateError] = useState<string>('');
    const [ updateInProgress, setUpdateInProgress] = useState<boolean>(false);

    async function validateAndActivate(password: string) {

        setUpdateInProgress(true);
        
        try {
            await handleAccountActivate(
                urlParams.invite as string,
                urlParams.source as string,
                password);

            setUpdateError('');
            setIsSuccess(true)
        }
        catch {
            setUpdateError("L'activation a échoué.");
        }
        finally {
            setUpdateInProgress(false);
        }
    }

    return (
      <InterractiveSection title = { sectionTitle } >
            <div className="w3-container">
                {
                    isSuccess
                        ? <div>
                            <p className="w3-panel w3-green">Le compte a été activé, veuillez vous rendre à la page de connexion.</p>
                            <p><a href={"/login/"}>Page de connexion</a></p>
                        </div>
                        : <SecretForm errorMessage={updateError} onSubmit={validateAndActivate}
                            updateInProgress={updateInProgress} button={SubmitButton.ACTIVATE} />
                }
            </div>
      </InterractiveSection>
    );
}